import { NgModule } from '@angular/core';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { PrivacyPolicyComponent } from './policy/privacy-policy/privacy-policy.component';
const routes: Routes = [
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    
    children: [
      { path: '', redirectTo: '/dashboard/main', pathMatch: 'full' },     
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
       
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'reports',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./reports/reports.module').then((m) => m.ReportsModule),
      },
      {
        path: 'qc',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./qc/qc.module').then((m) => m.QcModule),
      },
      {
        path: 'ui',
        loadChildren: () => import('./ui/ui.module').then((m) => m.UiModule),
      },
      {
        path: 'charts',
        loadChildren: () =>
          import('./charts/charts.module').then((m) => m.ChartsModule),
      },
      {
        path: 'extra-pages',
        loadChildren: () =>
          import('./extra-pages/extra-pages.module').then(
            (m) => m.ExtraPagesModule
          ),
      },
      {
        path: 'history',
        // canActivate: [AuthGuard],
        loadChildren: () =>
          import('./history/history.module').then((m) => m.HistoryModule),
      },
      {
        path: 'target',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./target/target.module').then((m) => m.TargetModule),
      },
      {
        path: 'submission',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./submission/submission.module').then((m) => m.SubmissionModule),
      },
      {
        path: 'task',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./task/task.module').then((m) => m.TaskModule),
      },
      {
        path: 'administration',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./administration/administration.module').then((m) => m.AdministrationModule),
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingModule),
      },
      {
        path: 'accountsMaster',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./accounts-master/accounts-master.module').then((m) => m.AccountsMasterModule),
      },
      {
        path: 'inventory',
        canActivate: [AuthGuard],
       
        loadChildren: () =>
          import('./inventory/inventory.module').then((m) => m.InventoryModule),
      },
      {
        path: 'tracking',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./tracking/tracking.module').then((m) => m.TrackingModule),
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingModule),
      },
      {

        path: 'inspection',
        canActivate: [AuthGuard],
        loadChildren: () =>
         import('./replacement-inspection/replacement-inspection.module').then((m) => m.ReplacementInspectionModule)},
         {

        path: 'replacement-qc',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./replacement-qc/replacement-qc.module').then((m) => m.ReplacementQcModule),

      },
      {
        path:'ticketing',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./ticketing/ticketing.module').then((m) => m.TicketingModule),
      }
    ],
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: '**', component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
