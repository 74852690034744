<div class="dialog_scrim" *ngIf="openMenu">
</div>

<button mat-fab style="background-color:#164A84"(click)="clickMenu()"
    class="menu-button"
    [class.mat-elevation-z2]="!isOver"
    [class.mat-elevation-z8]="isOver"
    (mouseover)="isOver = true"
    (mouseleave)="isOver = false">
  <i class="material-icons" *ngIf="!openMenu">markunread</i>
  <i class="material-icons" *ngIf="openMenu">close</i>
</button>

<!-- menu button -->
<button 
  mat-mini-fab 
  class="action-button one mat-elevation-z8"    
  *ngIf="openMenu"
  color="basic" 
  (click)="createTicket()">
  <span><i class="material-icons icon-small">add</i></span>
 
</button>
<!-- <div class="btn one action-button mat-elevation-z8" *ngIf="openMenu">
    <span>Let's Go to the Moon !</span>
    
    <i class="material-icons icon-small ico"></i>
   
    </div> -->

<!-- <button mat-raised-button 
  class="label labelone mat-elevation-z8" 
  *ngIf="openMenu"
  style="color:#164A84" 
  (click)="hello('button one')">
  Say Hello One
</button> -->

<button mat-mini-fab 
  class="action-button two mat-elevation-z8"    
  *ngIf="openMenu"
  color="basic" 
  (click)="createTicket()">
  <span><i class="material-icons">content_copy</i></span>

</button>
<!-- <button mat-raised-button 
  class="label labeltwo mat-elevation-z8" 
  *ngIf="openMenu"
  style="color:#164A84"
  (click)="hello('button two')">
  A
</button> -->