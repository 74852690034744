import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-floating-button',
  templateUrl: './floating-button.component.html',
  styleUrls: ['./floating-button.component.scss']
})
export class FloatingButtonComponent implements OnInit {
  public openMenu: boolean = false;
  isOver = false;
  constructor(  private router: Router) { }

  ngOnInit(): void {
  }
  clickMenu(){
    this.openMenu = !this.openMenu;
  }

  createTicket(){
      this.router.navigate(['/ticketing/ticket-Dashboard'])
  }
  

}
